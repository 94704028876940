import { AccountTypeNames, FinancialOperationTypeEnum } from '@medentee/enums'
import { useTranslation } from 'react-i18next'
import { TFunction } from 'i18next'
import { ReactNode } from 'react'

import { getMapComponent } from 'utils'

import { Transfer } from './Transfer'

export type TOperationDetailsProps = {
  type: FinancialOperationTypeEnum

  subscriptionName?: string
  accountType?: AccountTypeNames
  displayUserName?: string
}

type TOperationTypeMapProps = Omit<TOperationDetailsProps, 'type'> & {
  t: TFunction
}

const OPERATION_TYPE_MAP = new Map<
  FinancialOperationTypeEnum,
  ({ subscriptionName }: TOperationTypeMapProps) => ReactNode
>()
  .set(FinancialOperationTypeEnum.GENERATION, ({ t }) =>
    t('payments.operationHistory.details.GENERATION')
  )
  .set(FinancialOperationTypeEnum.BONUS, ({ t }) => t('payments.operationHistory.details.BONUS'))
  .set(FinancialOperationTypeEnum.SUBSCRIPTION, ({ subscriptionName, t }) =>
    t('payments.operationHistory.details.SUBSCRIPTION', { subscriptionName })
  )
  .set(FinancialOperationTypeEnum.TRANSFER, Transfer)
  .set(FinancialOperationTypeEnum.TOP_UP, ({ t }) => t('payments.operationHistory.details.TOP_UP'))
  .set(FinancialOperationTypeEnum.AUTO_TOP_UP, ({ t }) =>
    t('payments.operationHistory.details.AUTO_TOP_UP')
  )
  .set(FinancialOperationTypeEnum.MONTHLY_BONUS, ({ t }) =>
    t('payments.operationHistory.details.MONTHLY_BONUS')
  )

export const OperationDetails = ({
  type,
  subscriptionName,
  accountType,
  displayUserName
}: TOperationDetailsProps) => {
  const { t } = useTranslation()

  return (
    <>
      {getMapComponent(OPERATION_TYPE_MAP, type, {
        subscriptionName,
        accountType,
        displayUserName,
        t
      })}
    </>
  )
}
